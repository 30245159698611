import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Button as AntdButton,
  Card,
  InputRef,
  Table,
  TableColumnType,
  Layout
} from "antd"; // Import Table from antd
import {Content} from "antd/lib/layout/layout";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  CloseOutlined,
  CommentOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

//utils
import { BASE_URL } from "utils/constants";
import { Role, User, UserIndex } from "utils/interfaces";
import { FilterDropdownProps } from "antd/es/table/interface";
import Search from "antd/es/input/Search";
import CommentsComponent from "../Components/CommentsComponent";
import DeleteModal from "../Components/DeleteModal";

axios.defaults.withCredentials = true;

const UsersPage: React.FC = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<User>();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: UserIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void, close: () => void) => {
    clearFilters();
    setSearchText("");

    close();
  };
  const onSubmit: SubmitHandler<User> = (data) => {
    console.log(data);
    axios
      .post(`${BASE_URL}add_user`, {
        user_email: data.user_email,
        role_id: data.role_name?.id,
      })
      .then((response) => {
        console.log(response.data);
        updateUsers();
        toggleAddModal();
      })
      .catch((error) => {
        console.error("Error fetching Users:", error);
      });
  };
  const emptyUser: User = { id: 0, role_name: undefined, user_email: "" };
  const updateUser: SubmitHandler<User> = (data) => {
    // const emailToSend = newUserEmail !== "" ? newUserEmail : selectedUser?.user_email;

    axios
      .post(`${BASE_URL}update_user/${data?.id}`, {
        user_email: data.user_email,
        role_id: data.role_name?.id,
      })
      .then((response) => {
        updateUsers();
        toggleEditModal();
      })
      .catch((error) => {
        console.error("Error fetching Users:", error);
      });
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };
  const toggleAddModal = () => {
    reset(emptyUser);
    setShowAddModal(!showAddModal);
  };

  const toggleApiModal = () => {
    setShowApiKeyModal(!showApiKeyModal);
  };
  const openCommentModal = (user: User) => {
    reset(user);
    setSelectedUser(user);
    setSelectedUserId(user.id);
    setShowCommentModal(true);
  };
  const closeCommentModal = () => {
    setShowCommentModal(false);
  };

  useEffect(() => {
    setLoading(true);
    // Fetch the users from the api
    updateUsers();
    //fetch roles
    updateRoles();
    setLoading(false);

  }, []);
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  const getColumnSearchProps = (
    dataIndex: UserIndex
  ): TableColumnType<User> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Search
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onSearch={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "flex" }}
          allowClear={{
            clearIcon: (
              <CloseOutlined
                onClick={() => clearFilters && handleReset(clearFilters, close)}
              />
            ),
          }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <>
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      </>
    ),
    onFilter: (value, record) =>
      // @ts-ignore
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // Columns definition for Ant Design Table
  const columns = [
    {
      title: "Email",
      dataIndex: "user_email",
      key: "user_email",
      showSorterTooltip: { target: "full-header" },
      sorter: (a: User, b: User) => a.user_email.localeCompare(b.user_email),
      ...getColumnSearchProps("user_email"),
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      showSorterTooltip: { target: "full-header" },
      sorter: (
        a: { role_name: string | undefined },
        b: { role_name: undefined }
      ) => {
        if (a.role_name !== undefined && b.role_name !== undefined) {
          return a.role_name.localeCompare(b.role_name);
        } else {
          return 0;
        }
      },
      ...getColumnSearchProps("role_name"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text: any, record: any) => (
        <div>
          <AntdButton onClick={() => handleEdit(record)}>Edit</AntdButton>

          <AntdButton
            style={{ marginRight: "16px", marginLeft: "16px" }}
            onClick={() => handleApiKey(record.id)}
          >
            API Key
          </AntdButton>
          <AntdButton danger onClick={() => handleDeleteRole(record)}>
            Delete
          </AntdButton>
        </div>
      ),
    },
    {
      title: "",
      key: "comments",
      render: (_text: any, record: any) => (
        <div>
          <CommentOutlined
            style={{ fontSize: 20 }}
            onClick={() => openCommentModal(record)}
          />
        </div>
      ),
    },
  ];

  const updateRoles = () => {
    axios
      .get(`${BASE_URL}roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  };
  // const handleRoleSelect = (selectedOption: Role | null) => {
  //     if (selectedOption) {
  //         register = ...register("role", selectedOption)
  //     }
  //
  // }
  const updateUsers = () => {
    axios
      .get(`${BASE_URL}get_users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Users:", error);
      });
  };

  const handleEdit = (user: User) => {
    // Set the values of the selected user in the form fields
    reset(user);
    setSelectedUser(user);
    toggleEditModal();
  };

  const handleDeleteRole = (user: User) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const confirmDeleteRole = (userId: number) => {
    // Handle delete action, you can console log or implement your logic here
    console.log("Delete User with ID:", userId);
    axios
      .post(`${BASE_URL}delete_user/${userId}`)
      .then((response) => {
        updateUsers();
      })
      .catch((error) => {
        console.error("Error fetching Users:", error);
      });
    setShowDeleteModal(false);
  };

  const handleApiKey = (userId: number) => {
    console.log("Setting up the api key", userId);
    axios
      .post(`${BASE_URL}generate_api_key/${userId}`)
      .then((response) => {
        console.log(response);
        console.log(response.data);
        const api_key = response.data.content;
        setApiKey(api_key);
        toggleApiModal();
      })
      .catch((error) => {
        const response = error.response.data;
        const api_key = response.reason;
        setApiKey(api_key);
        toggleApiModal();
        console.error("Error fetching Users:", error);
      });
  };

  return (
    <div>
      <Layout className="layout" style={{height: "100vh"}}>
        <Content style={{margin: '24px 16px 0', overflow: 'initial', height: "100vh", boxSizing: "border-box"}}>
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{textAlign: 'left' }}>Role Management</span>
                <span style={{textAlign: 'right'}}>
                  <Button onClick={toggleAddModal} style={{fontSize:12}}>Add User</Button>
                </span>
              </div>
            }
          >
            <CommentsComponent
              showModal={showCommentModal}
              onClose={closeCommentModal}
              commentTarget={"USER"}
              targetId={selectedUserId}
              targetObject={selectedUser}
            ></CommentsComponent>
            <Modal show={showApiKeyModal} onHide={toggleApiModal}>
              <Modal.Header closeButton>
                <Modal.Title>API KEY</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h2>API Key</h2>
                <p>{apiKey}</p>
                <p>
                  This key can be set one time only and cannot be viewed afterwards.
                  Please save it to a secure location.
                </p>
              </Modal.Body>
            </Modal>
            <Modal show={showAddModal} onHide={toggleAddModal}>
              <Modal.Header closeButton>
                <Modal.Title>Adding new user</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <input
                    defaultValue=""
                    {...register("user_email", { required: true, minLength: 1 })}
                  />
                  {errors.user_email && <span>This field is required</span>}
                  <Select
                    isClearable
                    // defaultValue={}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    // menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    menuPlacement={"bottom"}
                    isSearchable
                    name="color"
                    menuShouldScrollIntoView={false}
                    options={roles}
                    getOptionLabel={(option) => option.role_name}
                    getOptionValue={(option) => option.role_name}
                    onChange={(selectedOption) =>
                      register("role_name", {
                        value: selectedOption
                          ? (selectedOption as Role)
                          : undefined,
                      })
                    }
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={toggleAddModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            <Table
              /*@ts-ignore*/
              columns={columns}
              dataSource={users}
              showSorterTooltip={{ target: "sorter-icon" }}
              loading={loading}
            />
            <Modal show={showEditModal} onHide={toggleEditModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Editing access for {selectedUser?.user_email}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <input
                    defaultValue={watch("user_email")}
                    {...register("user_email", {
                      required: true,
                      minLength: 1,
                    })}
                  />
                  <Select
                    isClearable
                    defaultValue={roles.find(
                      // @ts-ignore
                      (role) => role.id === watch("role_id")
                    )}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    // menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    menuPlacement={"bottom"}
                    isSearchable
                    name="color"
                    menuShouldScrollIntoView={false}
                    options={roles}
                    getOptionLabel={(option) => option.role_name}
                    getOptionValue={(option) => option.role_name}
                    onChange={(selectedOption) =>
                      register("role_name", {
                        value: selectedOption
                          ? (selectedOption as Role)
                          : undefined,
                      })
                    }
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={toggleEditModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmit(updateUser)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            <DeleteModal
              showModal={showDeleteModal}
              onDelete={() => confirmDeleteRole(selectedUser!.id)}
              onClose={() => setShowDeleteModal(false)}
            />
          </Card>
        </Content>
      </Layout>
    </div>
  );
};

export default UsersPage;
