import React, { useState } from "react";
import axios from "axios";

import { Button, Card, Table, Tooltip } from "antd"; // Import Table from antd
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
// utils
import { BASE_URL } from "utils/constants";
import { JsonSchemaItem, JsonSchemaListResponse } from "utils/interfaces";

import JsonSchemaListViewAddModal from "./JsonSchemaListComp/AddModal";
import JsonSchemaListViewEditModal from "./JsonSchemaListComp/EditModal";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DeleteModal from "./DeleteModal";

const JsonSchemaListView: React.FC = () => {
  const queryClient = useQueryClient();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedJsonSchema, setSelectedJsonSchema] =
    useState<JsonSchemaItem>();
  const [jsonSchemaEdit, setJsonSchemaEdit] = useState<JsonSchemaItem>();

  const jsonSchemaListQuery = useQuery({
    queryKey: ["jsonSchemaList"],
    queryFn: async () => {
      const res = await axios.get<JsonSchemaListResponse>(
        `${BASE_URL}/get_json_schemas_base`
      );
      return res.data.content;
    },
  });

  const deleteJsonSchema = useMutation({
    mutationFn: (id: number) =>
      axios.post(`${BASE_URL}/delete_json_schema_base/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jsonSchemaList"] });
      queryClient.invalidateQueries({ queryKey: ["baseFirmwareList"] });
      queryClient.invalidateQueries({ queryKey: ["vehicleList"] });
    },
  });

  const handleDelete = (jsonSchema: JsonSchemaItem) => {
    setSelectedJsonSchema(jsonSchema);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    deleteJsonSchema.mutate(selectedJsonSchema!.id);

    setShowDeleteModal(false);
  };
  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  // Columns definition for Ant Design Table
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Schema Version Name",
      dataIndex: "version_schema_name",
      key: "version_schema_name",
    },
    {
      title: "Json Config",
      dataIndex: "json_schema",
      key: "json_schema",
      render: (_text: any, record: any) => (
        <EditOutlined onClick={() => handleJsonSchemaEdit(record)} />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text: any, record: any) => (
        <div>
          <Button danger onClick={() => handleDelete(record)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleJsonSchemaEdit = (record: JsonSchemaItem) => {
    setJsonSchemaEdit(record);
    console.log(record);
    toggleEditModal();
  };

  return (
    <div>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            JSON Schema Management
            <Tooltip title="Add New Schema">
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={toggleAddModal}
              />
            </Tooltip>
          </div>
        }
      >
        <JsonSchemaListViewEditModal
          jsonSchema={jsonSchemaEdit!}
          showModal={showEditModal}
          onClose={toggleEditModal}
        />
        <JsonSchemaListViewAddModal
          showModal={showAddModal}
          onClose={toggleAddModal}
        />
        <DeleteModal
          showModal={showDeleteModal}
          onDelete={() => confirmDelete()}
          onClose={() => setShowDeleteModal(false)}
        />
        <Table
          columns={columns}
          dataSource={jsonSchemaListQuery.data}
          loading={jsonSchemaListQuery.isLoading}
        />
      </Card>
    </div>
  );
};

export default JsonSchemaListView;
