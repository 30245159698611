import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Checkbox,Layout, Spin } from "antd"; // Import Table from antd
import {Content} from "antd/lib/layout/layout";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { useForm, SubmitHandler } from "react-hook-form";
// utils
import { BASE_URL } from "utils/constants";
import { Role, Endpoint } from "utils/interfaces";

axios.defaults.withCredentials = true;
const RolesPage: React.FC = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [roleAccess, setRoleAccess] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Role>();
  const onSubmit: SubmitHandler<Role> = (data) => {
    console.log(data);
    axios
      .post(`${BASE_URL}add_role`, {
        role_name: data.role_name,
        allowed_endpoints: [],
      })
      .then((response) => {
        console.log("Role added succesfully:", response.data);
        // Clear selected role and role access
        updateRoles();
        toggleAddModal();
      })
      .catch((error) => {
        console.error("Error deleting role:", error);
      });
  };

  useEffect(() => {
    setLoading(true);
    // Fetch roles from API
    updateRoles();

    // Fetch endpoints from API
    axios
      .get(`${BASE_URL}get_url_map`)
      .then((response) => {
        setEndpoints(response.data);
      })
      .catch((error) => {
        console.error("Error fetching endpoints:", error);
      });
      setLoading(false);
  }, []);

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };
  const updateRoles = () => {
    axios
      .get(`${BASE_URL}roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  };

  const handleRoleSelect = (value: Role | null) => {
    console.log(value);
    setSelectedRole(value);
    // Find selected role details and set role access

    if (value) {
      const access: { [key: string]: boolean } = {};
      // Initialize access object with false for all endpoints
      endpoints.forEach((endpoint) => {
        access[endpoint.endpoint] = false;
      });
      // Set access to true for allowed endpoints
      value.allowed_endpoints.forEach((endpoint) => {
        access[endpoint] = true;
      });
      setRoleAccess(access);
    }
  };

  const handleEndpointAccessChange = (endpoint: string, checked: boolean) => {
    setRoleAccess((prevState) => ({
      ...prevState,
      [endpoint]: checked,
    }));
  };

  const handleSave = () => {
    // Save role access

    const allowedEndpointsArray = Object.keys(roleAccess).filter(
      (endpoint) => roleAccess[endpoint]
    );
    const payload = {
      allowed_endpoints: allowedEndpointsArray,
    };
    axios
      .post(`${BASE_URL}update_role/${selectedRole?.id}`, payload)
      .then((response) => {
        console.log("Role access saved successfully:", response.data);
        updateRoles();
      })
      .catch((error) => {
        console.error("Error saving role access:", error);
      });
  };

  const handleDelete = () => {
    // Delete selected role
    axios
      .post(`${BASE_URL}delete_role/${selectedRole?.id}`)
      .then((response) => {
        console.log("Role deleted successfully:", response.data);
        // Clear selected role and role access
        setSelectedRole(null);
        setRoleAccess({});
        updateRoles();
      })
      .catch((error) => {
        console.error("Error deleting role:", error);
      });
  };

  return (
    <div>
      <Layout className="layout" style={{height: "100vh"}}>
        <Content style={{margin: '24px 16px 0', overflow: 'initial', height: "100vh", boxSizing: "border-box"}}>
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{textAlign: 'left' }}>Role Management</span>
                <span style={{textAlign: 'right', display: 'flex', gap: '5px' }}>
                  <Button onClick={handleSave} style={{fontSize:12}}>Save</Button>
                  <Button onClick={toggleAddModal} style={{fontSize:12}}>New Role</Button>
                </span>
              </div>
            }
          >
            <Modal show={showAddModal} onHide={toggleAddModal}>
              <Modal.Header closeButton>
                <Modal.Title>Adding new Role</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <input
                    defaultValue=""
                    {...register("role_name", { required: true, minLength: 1 })}
                  />
                  {errors.role_name && <span>This field is required</span>}
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={toggleAddModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            <Spin size="large" spinning={loading}>
            <div>
              <Select
                isClearable
                defaultValue={roles[0]}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                // menuPortalTarget={document.body}
                menuPosition={"fixed"}
                menuPlacement={"bottom"}
                isSearchable
                name="color"
                menuShouldScrollIntoView={false}
                options={roles}
                getOptionLabel={(option) => option.role_name}
                getOptionValue={(option) => option.role_name}
                onChange={(selectedOption) => handleRoleSelect(selectedOption)}
              />
              {selectedRole && <Button onClick={handleDelete}>Delete</Button>}
            </div>
            <div>
              {endpoints.map((endpoint) => (
                <div key={endpoint.endpoint}>
                  <Checkbox
                    checked={roleAccess[endpoint.endpoint]}
                    onChange={(e) =>
                      handleEndpointAccessChange(
                        endpoint.endpoint,
                        e.target.checked
                      )
                    }
                  >
                    {endpoint.endpoint} - {endpoint.location}
                  </Checkbox>
                </div>
              ))}
            </div>
            </Spin>
          </Card>
        </Content>
      </Layout>
    </div>
  );
};

export default RolesPage;
