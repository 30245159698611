import React, { useEffect, useState } from "react";
import { Card, Table, Tabs, Button, Radio, Modal, Spin, Layout } from "antd";
import {Content} from "antd/lib/layout/layout";
import { BASE_URL } from "utils/constants";
import axios from "axios";
import { RecordedVehicles } from "utils/interfaces";
import { CommentOutlined } from "@ant-design/icons";

interface VerticalTab {
  key: string;
  tab: string;
}

interface VehicleModelTab {
  key: string;
  tab: string;
}

const RecordDataPage = () => {
  const [activeTab, setActiveTab] = useState<string>("");
  const [activeModelTab, setActiveModelTab] = useState<string>("");
  const [recordedVehicles, setRecordedVehicles] = useState<RecordedVehicles[]>([]);
  const [verticalTabs, setVerticalTabs] = useState<VerticalTab[]>([]);
  const [vehicleModels, setVehicleModels] = useState<VehicleModelTab[]>([]);
  const [downloadFormat, setDownloadFormat] = useState<string>("ASC");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentContent, setCommentContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});

  const tableColumns = [
    { title: "Canny Device Id", dataIndex: "canny_device_serial_no", key: "canny_device_serial_no" },
    { title: "Created At", dataIndex: "created_at", key: "created_at" },
    { title: "Year", dataIndex: "vehicle_year", key: "vehicle_year" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Baud Rate", dataIndex: "buad_rate_speed", key: "buad_rate_speed" },
    {
      title: "Recorded Data",
      dataIndex: "recorded_file_list",
      key: "recorded_file_list",
      render: (recorded_file_list: any, vehicle: RecordedVehicles) => (
        <ul>
          {recorded_file_list.map((record: any) => {
            const key = `${vehicle.id}_${record.functionality.name}`;
            return (
              <ul key={record.id}>
                {record.file.comment && record.file.comment.trim() && (
                  <Button
                    type="text"
                    icon={<CommentOutlined style={{ fontSize: '20px' }} />}
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                      setCommentContent(record.file.comment);
                      setShowCommentModal(true);
                    }}
                  />
                )}
                <Button
                  style={{ marginLeft: 8 }}
                  loading={loadingStates[key] || false}  // Check loading state for specific key
                  onClick={() => handleDownload(vehicle.id, record.functionality.name, vehicle)}
                >
                  {record.functionality.name}
                </Button>
              </ul>
            );
          })}
        </ul>
      )
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (comment: string) => (
        comment && comment.trim() && (
          <Button
            type="text"
            icon={<CommentOutlined style={{ fontSize: '40px' }} />}
            style={{ marginLeft: 8}}
            onClick={() => {
              setCommentContent(comment);
              setShowCommentModal(true);
            }}
          />
        )
      ),
    },
  ];

  useEffect(() => {
    updateCannyDevices();
  }, []);

  const CommentModal = () => (
    <Modal
      title="Comment"
      open={showCommentModal}
      onCancel={() => setShowCommentModal(false)}
      footer={null}
    >
      <p>{commentContent}</p>
    </Modal>
  );

  const updateCannyDevices = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}recorded_vehicles`)
      .then((response) => {
        const vehicles: RecordedVehicles[] = response.data.content;
        setRecordedVehicles(vehicles);

        const manufacturers = Array.from(
          new Set(vehicles.map((vehicle) => vehicle.vehicle_name))
        );
        const tabs: VerticalTab[] = manufacturers.map((manufacturer) => ({
          key: manufacturer,
          tab: manufacturer,
        }));

        setVerticalTabs(tabs);

        // Set the first tab as active and initialize model tabs
        if (tabs.length > 0) {
          setActiveTab(tabs[0].key);
          const models = Array.from(
            new Set(
              vehicles
                .filter((device) => device.vehicle_name === tabs[0].key)
                .map((device) => device.vehicle_model)
            )
          );

          const modelTabs: VehicleModelTab[] = models.map((model) => ({
            key: model,
            tab: model,
          }));

          setVehicleModels(modelTabs);
          setActiveModelTab(models[0]); // Set the first model tab as active
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Canny Devices:", error);
        setLoading(false);
      });
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    const models = Array.from(
      new Set(
        recordedVehicles
          .filter((device) => device.vehicle_name === key)
          .map((device) => device.vehicle_model)
      )
    );

    const modelTabs: VehicleModelTab[] = models.map((model) => ({
      key: model,
      tab: model,
    }));

    setVehicleModels(modelTabs);
    setActiveModelTab(models[0]);
  };

  const handleModelTabChange = (key: string) => {
    setActiveModelTab(key);
  };

  const handleDownload = (id:number, functionalityName:string, vehicle: RecordedVehicles) => {
    const key = `${id}_${functionalityName}`;
    setLoadingStates((prev) => ({ ...prev, [key]: true }));

    axios
      .get(`${BASE_URL}download_recorded_vehicle_data/${id}/${functionalityName}`, {
        params: { format: downloadFormat.toLowerCase() },
        responseType: "blob",
      })
      .then((response) => {
        const href = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${vehicle.canny_device_serial_no}_${vehicle.vehicle_name}_${vehicle.vehicle_model}_${vehicle.vehicle_year}_${functionalityName}.${downloadFormat.toLowerCase()}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setLoadingStates((prev) => ({ ...prev, [key]: false }));
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        setLoadingStates((prev) => ({ ...prev, [key]: false }));
      });
  };

  const verticalTabItems = verticalTabs.map((vTab) => ({
    label: vTab.tab,
    key: vTab.key,
  }));

  const modelTabItems = vehicleModels.map((modelTab) => ({
    label: modelTab.tab,
    key: modelTab.key,
  }));

  return (
    <div>
      <Layout className="layout" style={{height: "100vh"}}>
        <Content style={{margin: '24px 16px 0', overflow: 'initial', height: "100vh", boxSizing: "border-box"}}>
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{textAlign: 'left' }}>Recorded Vehicles</span>
                <span style={{textAlign: 'right'}}>
                <Radio.Group
                  value={downloadFormat}
                  onChange={(e) => setDownloadFormat(e.target.value)}
                  buttonStyle="solid"
                >
                  <Radio.Button value="JSON">JSON</Radio.Button>
                  <Radio.Button value="ASC">ASC</Radio.Button>
                </Radio.Group>
                </span>
              </div>
            }
         >
            <Spin size="large" spinning={loading}>
              <div style={{ display: "flex" }}>
                <Tabs
                  tabPosition="left"
                  items={verticalTabItems}
                  onChange={handleTabChange}
                  style={{ width: 200 }}
                />
                <div style={{ marginLeft: 16, flex: 1 }}>
                  {verticalTabItems.map(
                    (vTab) =>
                      activeTab === vTab.key && (
                        <div key={vTab.key}>
                          <Tabs
                            items={modelTabItems}
                            onChange={handleModelTabChange}
                            defaultActiveKey={activeModelTab}
                          />
                          <Table
                            pagination={{ pageSize: 5 }}
                            columns={tableColumns}
                            dataSource={recordedVehicles.filter(
                              (device) =>
                                device.vehicle_name === vTab.key &&
                                device.vehicle_model === activeModelTab
                            )}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
              <CommentModal />
            </Spin>
          </Card>
        </Content>
      </Layout>
    </div>
  );
};

export default RecordDataPage;
